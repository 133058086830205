import Post from "./components/Post"
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {

  return(
    <Post />
  )
}

export default App